import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as MomentTimezone from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isAuthenticated()) {
      const headers = {
        Authorization: 'Bearer ' + this.authService.getToken(),
        'Content-type': 'application/json',
        accept: 'application/json',
        'User-Timezone': MomentTimezone.tz.guess(),
      };
      const headerAccept = req.headers.get('accept');
      if (headerAccept) {
        headers.accept = headerAccept;
      }
      // 'Accept' is used by the smartyID app requests.
      // does not support 'accept'
      if (req.headers.get('Accept')) {
        headers['Accept'] = req.headers.get('Accept');
      }
      // This is used to delete the content-type for uploading files;
      if (req.body && typeof req.body === 'object') {
        // Do not remove this!! will generate a deploy error (typescript 3.5 )
        // @ts-ignore
        if (req.body instanceof FormData) {
          delete headers['Content-type'];
        }
      }
      req = req.clone({ setHeaders: headers });
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // this.errorDialogService.openDialog(event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.authService.logout();
        }
        return throwError(error);
      }),
    );
  }
}
