import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PagesComponent } from './pages/pages.component';
import { MainMenuComponent } from './core/main-menu/main-menu.component';
import { PagesRoutingModule } from './pages/pages-routing.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AuthRoutingModule } from './auth/auth-routing.module';
import { LoginComponent } from './auth/login/login.component';
import { AuthInterceptorService } from './core/auth/auth.interceptor.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ToastrModule } from 'ngx-toastr';
import { DatetimeFormatInterceptorService } from './core/interceptors/datetime-format.service';
import { ShareToolsModule } from './shared/share-tools/share-tools.module';
import { AsyncUserQuickSearchModule } from './shared/async-user-quick-search/async-user-quick-search.module';

@NgModule({
  declarations: [AppComponent, PagesComponent, MainMenuComponent, LoginComponent],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PagesRoutingModule,
    ModalModule.forRoot(),
    AuthRoutingModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    NgHttpLoaderModule.forRoot(),
    ToastrModule.forRoot(),
    ShareToolsModule,
    AsyncUserQuickSearchModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DatetimeFormatInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
export const bsConfig = {
  date: 'MM/DD/YYYY',
  dateTimeSec: 'MM/DD/YYYY HH:mm:ss',
  dateTimeMin: 'MM/DD/YYYY HH:mm',
  detail: 'MMM DD, YYYY',
  hhmmA: 'hh:mmA',
  hh: 'hh',
  mm: 'mm',
  hmma: 'h:mmA',
  adaptivePosition: true,
  containerClass: 'theme-default',
  selectFromOtherMonth: true,
  showWeekNumbers: false,
  locale: 'engb',
  customTodayClass: 'today',
};
