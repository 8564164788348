import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuardService implements CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.authService.getLoggedUser();
    const roles = next.data.roles;
    if (roles.find((role) => role === user.mainRole)) {
      return true;
    }

    this.router.navigate(['/404']);
    return false;
  }
}
