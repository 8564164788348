import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatatablesSearchService {
  dtAjaxCall;
  timer: number;
  lastTime: number;
  now: number;
  searchDelay = 400;

  constructor(private http: HttpClient) {}

  searchDebouncer(url: string, callback): Observable<any> {
    const result$ = new BehaviorSubject<any>([]);
    const headers = new HttpHeaders().set('accept', 'application/ld+json');
    if (this.dtAjaxCall) {
      this.dtAjaxCall.unsubscribe();
    }
    this.now = +new Date();
    this.lastTime = this.now;
    if (this.lastTime && this.now <= this.lastTime + this.searchDelay) {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.lastTime = null;
        // DT table call
        this.dtAjaxCall = this.http.get<any>(url, { headers }).subscribe((response: any) => {
          this.dtAjaxCall = null;
          callback({
            recordsTotal: response['hydra:totalItems'],
            recordsFiltered: response['hydra:totalItems'],
            data: [],
          });
          if (response['hydra:member'].length > 0) {
            result$.next(response['hydra:member']);
          }
        });
      }, this.searchDelay);
    } else {
      this.lastTime = this.now;
      this.searchDebouncer(url, callback);
    }
    return result$;
  }
}
