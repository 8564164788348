import { Component, EventEmitter, OnInit, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import { getUrl } from '../../core/helpers/query-params.helper';
import { Location } from '@angular/common';
import {UserService} from '../../core/services/user.service';
import { User } from '../../core/models/user.model';

@Component({
  selector: 'app-async-user-quick-search',
  templateUrl: './async-user-quick-search.component.html',
  styleUrls: ['./async-user-quick-search.component.scss'],
})
export class AsyncUserQuickSearchComponent implements OnInit, OnChanges {
  @Input() userQuery: string;
  @Input() properties: any;
  @Input() groups: any;
  @Output() selectedUserEvent = new EventEmitter<User>();
  asyncSelected: string | number;
  typeaheadLoading: boolean;
  dataSource: Observable<any>;

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router
  ) {}

  ngOnInit() {
    this.dataSource = new Observable((observer: any) => {
      observer.next(this.asyncSelected);
    }).pipe(
      mergeMap((token: string) => {
        let properties =  ['id', 'fullName', 'email', 'smartyId', 'roles', 'parent', 'type'];
        if (this.properties) {
          properties = properties.concat(this.properties);
        }
        const groups = {};
        if (this.groups) {
          groups['groups[]'] = this.groups;
        }

        return this.userService.findAll({
          'properties[]': properties,
          pagination: true,
          search_name_email: token,
          ...groups,
        });
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.studentName.previousValue === null && changes.studentName.currentValue) {
      this.asyncSelected = changes.studentName.currentValue;
    }
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    if (e.item) {
      this.selectedUserEvent.emit(e.item);
      const user = e.item;
      let userPath = '';
      if (user.isCustomer) {
        userPath = '/customers/save/' + user.id;
      }
      if (user.isStudent) {
        userPath = '/customers/save/' + user.parent.id + '/students/save/' + user.id;
        this.router.routeReuseStrategy.shouldReuseRoute = () => {return false};
      }
      if (user.isTutor) {
        userPath = '/tutors/save/' + user.id;
      }
      if (user.isAdmin || user.isOrganizationAdmin) {
        userPath = '/users/save/' + user.smartyId;
      }

      if (userPath !== '') {
        this.router.navigate([userPath]).then(() => {this.asyncSelected = undefined});
      }
    }
  }

  clearUser(val) {
    if (!val) {
      this.selectedUserEvent.emit(null);
      // remove the student param
      const url = getUrl({}, window.location.href);
      url.searchParams.delete('student');
      this.location.replaceState(url.pathname + url.search);
    }
  }

}
