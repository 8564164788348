import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { User } from '../models/user.model';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  public smartyBubbleUrl: string;
  constructor(public authService: AuthService, private router: Router) {
    this.smartyBubbleUrl = environment.smartyBubbleUrl;
  }

  ngOnInit() {}

  get loggedUser(): User {
    return this.authService.getLoggedUser();
  }

  get activeDashboard() {
    return this.urlFinder(['/dashboard']);
  }

  get activeManage() {
    return this.urlFinder(['/customers', '/tutors', '/users', '/organizations']);
  }

  get activeBilling() {
    return this.urlFinder(['/invoices', '/payments']) && !this.urlFinder(['/reports/invoices']);
  }
  get activeReports() {
    return this.urlFinder(['reports']);
  }

  urlFinder(urls: string[]) {
    let active = false;
    let activeURL = [];
    activeURL = urls.filter((url) => {
      return this.router.url.indexOf(url) >= 0;
    });
    if (activeURL.length) {
      active = true;
    }
    return active;
  }
}
