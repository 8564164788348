import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsyncUserQuickSearchComponent } from './async-user-quick-search.component';
import { FormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [AsyncUserQuickSearchComponent],
  exports: [AsyncUserQuickSearchComponent],
  imports: [CommonModule, FormsModule, TypeaheadModule.forRoot()],
})
export class AsyncUserQuickSearchModule {}
