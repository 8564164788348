<input
  [(ngModel)]="asyncSelected"
  (ngModelChange)="clearUser($event)"
  [typeaheadAsync]="true"
  [typeahead]="dataSource"
  (typeaheadLoading)="changeTypeaheadLoading($event)"
  (typeaheadOnSelect)="typeaheadOnSelect($event)"
  [typeaheadOptionsLimit]="100"
  [typeaheadMinLength]="3"
  [typeaheadScrollable]="true"
  [typeaheadOptionsInScrollableView]="5"
  typeaheadWaitMs="500"
  typeaheadOptionField="fullName"
  placeholder="Customer/Tutor lookup..."
  class="form-control"
/>
<div *ngIf="typeaheadLoading">Loading</div>
