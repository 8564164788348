import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/auth/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginUrl = environment.loginUrl;
  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params) {
        if (this.router.url === '/logout') {
          this.authService.logout();
        } else {
          this.authService.login(params);
        }
      }
    });
  }
}
