import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class DatetimeFormatInterceptorService implements HttpInterceptor {
  public static propertiesToCheck = [
    'date',
    'createdAt',
    'updatedAt',
    'initDate',
    'startTime',
    'startDate',
    'endDate',
    'endTime',
    'statusUpdatedAt',
    'referralActive',
    'actDate',
    'satDate',
    'datetime_start',
    'datetime_end',
    'excludedDates',
    'referralActive',
    'targetTestDates',
    // There is the 'stationDay' field which should always skip it
  ];
  private static convertToLocal(object) {
    for (const prop in object) {
      if (object.hasOwnProperty(prop)) {
        let objVal = object[prop];
        if (objVal) {
          if (objVal instanceof Array) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < objVal.length; i++) {
              if (objVal[i] instanceof Array || typeof objVal[i] === 'object') {
                object[prop][objVal[i]] = DatetimeFormatInterceptorService.convertToLocal(objVal[i]);
              }
              // If dates are in an array (applies to targetTestDates)
              if (this.propertiesToCheck.includes(prop) && typeof objVal[i] === 'string') {
                object[prop][objVal[i]] = new momentTimezone(objVal[i]).utc().local().format('YYYY-MM-DD HH:mm:ss');
              }
            }
          } else if (typeof objVal === 'object' && Object.keys(objVal).length > 0) {
            object[prop] = DatetimeFormatInterceptorService.convertToLocal(objVal);
          } else if (this.propertiesToCheck.includes(prop)) {
            // Skip time that includes only hour
            if (prop.indexOf('Time') && objVal.length <= 5) {
              continue; // eg.: 9:30
            }

            // This format is coming from SmartyBubble
            if (objVal.substr(-1) === 'Z') {
              object[prop] = new momentTimezone(objVal, 'YYYY-MM-DD HH:mm:ss.SSSZ')
                .utc()
                .local()
                .format('YYYY-MM-DD HH:mm:ss');
              continue;
            }

            // Fix to cleanup timezone comming from server. TODO - fix the server issue
            if (objVal.indexOf('+')) {
              objVal = objVal.split('+')[0] + '+00:00';
            }
            // object[prop] = momentTimezone(objVal).utc().local().add('-7', 'hours').format('YYYY-MM-DD HH:mm:ss');
            object[prop] = new momentTimezone(objVal).utc().local().format('YYYY-MM-DD HH:mm:ss');
            // object[prop] = new momentTimezone(objVal).utc().local().format();
          }
        }
      }
    }

    return object;
  }

  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Todo check if we should use propertiesToCheck
    const propertiesToIgnore = [
      'graduationYear',
      'birthDate'
    ]
    if (this.authService.isAuthenticated()) {
      if (req.method === 'POST' || req.method === 'PUT') {
        const body = req.body;
        if (typeof body === 'object' && Object.keys(body).length > 0) {
          for (const i in body) {
            // if (typeof body.convertTimeTo === 'function') {
            //   body.convertTimeTo('utc');
            // } else
            if (body.hasOwnProperty(i)) {
              let prop = body[i];
              // USCRM-1273 Added property ignore list
              if (propertiesToIgnore.indexOf(i) > -1) {
                continue;
              }
              if (typeof prop === 'string' && moment(prop, moment.ISO_8601, true).isValid()) {
                prop = moment(prop);
              }
              if (prop instanceof moment || prop instanceof momentTimezone) {
                body[i] = prop.clone().utc();
              }
            }
          }
        }
      }
      req = req.clone();
    }
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          let newBody = event.body;
          if (event.body !== null) {
            newBody = DatetimeFormatInterceptorService.convertToLocal(event.body);
          }
          return event.clone({ body: newBody });
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (typeof error.error !== 'undefined' && typeof error.error.detail !== 'undefined') {
          /**
           * This fails on session save when detail is a string and  not an stringifyied object but is required also for the session save
           */
          try {
            let errorBody = JSON.parse(error.error.detail);
            if (errorBody) {
              errorBody = DatetimeFormatInterceptorService.convertToLocal(errorBody);
              error.error.detail = JSON.stringify(errorBody);
            }
          } catch (e) {}
        }
        return throwError(error);
      }),
    );
  }
}
