import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../core/auth/auth.service';
import { User } from '../../core/models/user.model';
import { ToastrService } from 'ngx-toastr';
import { OrganizationDataService } from '../../core/services/organization-data.service';

@Component({
  selector: 'app-share-tools',
  templateUrl: './share-tools.component.html',
  styleUrls: ['./share-tools.component.scss'],
})
export class ShareToolsComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private toastr: ToastrService,
    private organizationDataService: OrganizationDataService,
  ) {}

  ngOnInit() {
    // this.loggedUser = this.authService.getLoggedUser();
  }

  get organizationData() {
    return this.organizationDataService.organizationData
      ? this.organizationDataService.organizationData
      : this.loggedUser.organizationSummary;
  }

  get loggedUser(): User {
    return this.authService.getLoggedUser();
  }

  copyMessage() {
    const registrationFormShareableUrl = this.loggedUser.registrationFormShareableUrl();
    if (registrationFormShareableUrl) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = this.loggedUser.registrationFormShareableUrl();
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
      this.toastr.info('The registration form URL has been copied to clipboard!');
    } else {
      this.toastr.error('No URL is set on your organization! Please contact the administrator.');
    }
  }
}
