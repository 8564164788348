import { Organization } from './organization.model';
import {Customer} from './customer.model';

export class User {
  public static ROLE_ADMIN = 'ROLE_ADMIN';
  public static ROLE_CUSTOMER = 'ROLE_CUSTOMER';
  public static ROLE_TUTOR = 'ROLE_TUTOR';
  public static ROLE_STUDENT = 'ROLE_STUDENT';
  public static ROLE_ORGANIZATION_ADMIN = 'ROLE_ORGANIZATION_ADMIN';
  public static USER_ROLES = {
    [User.ROLE_ADMIN]: 'Admin',
    [User.ROLE_ORGANIZATION_ADMIN]: 'Organization Admin',
    [User.ROLE_CUSTOMER]: 'Customer',
    [User.ROLE_TUTOR]: 'Tutor',
    [User.ROLE_STUDENT]: 'Student',
  };
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  roles: string[];
  rolesNames: string;
  primaryPhone: string;
  additionalPhone: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  active: string;
  appAccess: [] = [];
  smartyId: string;
  name: string;
  timezone: string;
  /** @deprectated */
  organization: string; // the prop name should match the API prop name
  organizationSummary: Organization;
  hasAdminAccess: boolean;
  hasPaymentDetails: boolean;
  parentId: number;

  constructor(values: any = []) {
    if (typeof values.organizationSummary === 'object') {
      values.organizationSummary = new Organization(values.organizationSummary);
      values.organization = values.organizationSummary.id;
    }
    if (values.roles) {
      const r = [];
      values.roles.map((rol) => {
        r.push(User.USER_ROLES[rol]);
      });
      values.rolesNames = r.join(',');
    }
    Object.assign(this, values);

    this.hasPaymentDetails = !!values.stripeAccountId;

    if (this.roles) {
      this.hasAdminAccess = this.isAdmin || this.isOrganizationAdmin;
    }
  }
  get IRI() {
    return '/users/' + this.id;
  }
  isEmpty() {
    return this.id === undefined;
  }
  get isCustomer() {
    return this.roles.indexOf(User.ROLE_CUSTOMER) > -1;
  }
  get isAdmin() {
    return this.roles.indexOf(User.ROLE_ADMIN) > -1;
  }
  get isOrganizationAdmin() {
    return this.roles.indexOf(User.ROLE_ORGANIZATION_ADMIN) > -1;
  }
  get isTutor() {
    return this.roles.indexOf(User.ROLE_TUTOR) > -1;
  }
  registrationFormShareableUrl() {
    let leadsRegistrationUrl = '';
    if (this.organizationSummary.leadsRegistrationFormUrl) {
      leadsRegistrationUrl = this.organizationSummary.leadsRegistrationFormUrl;
      if (leadsRegistrationUrl.indexOf('?') !== -1) {
        leadsRegistrationUrl += '&referral=' + this.id;
      } else {
        leadsRegistrationUrl += '?referral=' + this.id;
      }
    }

    return leadsRegistrationUrl;
  }
  get isStudent() {
    return this.roles.indexOf(User.ROLE_STUDENT) > -1;
  }
  get mainRole() {
    return this.roles ? this.roles[0] : false;
  }
}
