import { Injectable } from '@angular/core';
import { Organization } from '../models/organization.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationDataService {
  organizationData: Organization;

  constructor() {}
}
