import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';

// do not mutate queryParams[i] because this will lead to unexpected behavior on repeated requests
const getUrl = (queryParams = {}, endpoint) => {
  const url = new URL(endpoint);
  if (queryParams) {
    for (const i in queryParams) {
      if (queryParams.hasOwnProperty(i)) {
        // If parameter is array, add them all
        if (queryParams[i] instanceof Array) {
          queryParams[i].map((param) => {
            url.searchParams.append(i, param);
          });
        } else if (queryParams[i] instanceof moment || queryParams[i] instanceof momentTimezone) {
          // check for moment objects
          // queryParams[i] = queryParams[i].utc().format('YYYY-MM-DD HH:mm');
          url.searchParams.set(i, queryParams[i].clone().utc().format('YYYY-MM-DD HH:mm'));
        } else if (
          queryParams[i] !== null
          && typeof queryParams[i] !== 'undefined'
          && moment(queryParams[i].toString().replaceAll('Z', ''), 'YYYY-MM-DDTHH:mm:ss', true).isValid()
        ) {
          // check if iso format
          // queryParams[i] = moment(queryParams[i]).utc().format('YYYY-MM-DD HH:mm');
          url.searchParams.set(i, moment(queryParams[i]).utc().format('YYYY-MM-DD HH:mm'));
        } else {
          let val = queryParams[i];
          // If value param is an object, add his ID
          if (typeof queryParams[i] === 'object' && queryParams[i] && queryParams[i].hasOwnProperty('id')) {
            val = queryParams[i].id;
          }
          // Overwrite parameters with same key
          if (url.searchParams.get(i)) {
            url.searchParams.set(i, val);
          } else {
            url.searchParams.append(i, val);
          }
        }
      }
    }
  }
  return url;
};

const generateUrl = (queryParams = {}, endpoint) => {
  return getUrl(queryParams, endpoint).toString();
};

export { generateUrl };
export { getUrl };
