import * as moment from 'moment-timezone';

export class Engagement {
  id: number;
  type: string;
  body: string;

  constructor(values: any = {}) {
    Object.assign(this, values);
  }
}
