import { OrganizationService } from '../services/organization.service';

export class Organization {
  id: string;
  active: boolean;
  name: string;
  stripePublicKey: string;
  stripeSecretKey: string;
  referralLinkText: string;
  referralDetails: string;
  leadsRegistrationFormUrl: string;
  notificationsEmail: string;
  createdAt: string;

  constructor(values: any = []) {
    Object.assign(this, values);
  }
  get IRI() {
    return '/api/organizations/' + this.id;
  }
}
