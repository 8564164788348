import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/auth/auth.service';
import * as moment from 'moment-timezone';
import { defineLocale, enGbLocale } from 'ngx-bootstrap';
defineLocale('engb', enGbLocale);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  userDetails: any = {};
  constructor(public authService: AuthService) {}

  ngOnInit() {
    moment.updateLocale('en', {
      week: {
        dow: 1,
        doy: 4,
      },
    });
    // this.userDetails = this.authService.getLoggedUser();
    // moment.tz.setDefault(this.userDetails.timezone);
  }
}
