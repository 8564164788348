import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly TOKEN_STORAGE_KEY = 'token';
  private readonly USER_STORAGE_KEY = 'user';
  private readonly TOKEN_REQUEST_KEY = 'access';
  private readonly USER_REQUEST_KEY = 'user';
  private readonly LAST_LOGIN_STORAGE_KEY = 'login';
  private readonly SESSION_LIMIT = 86400; // seconds

  private readonly loginUrl = '/login';
  private readonly whereToUrl = '/index';
  private readonly smartyIdAccountUrl = environment.smartyIdAccountUrl;
  private readonly smartyCrmApiLoginUrl = environment.loginUrl;
  private readonly smartyCrmApiLogoutUrl = environment.crmServerBaseUrl + '/logout';

  private storage: Storage = window.localStorage;
  private sessionExpired: boolean = null;

  constructor(private http: HttpClient, private router: Router) {}

  isAuthenticated(): boolean {
    return this.getToken() !== null && !this.isSessionExpired();
  }

  isSessionExpired(): boolean {
    // cache the session expired check because there are multiple calls per page
    if (this.sessionExpired === null) {
      const lastLogin = parseInt(this.storage.getItem(this.LAST_LOGIN_STORAGE_KEY), 16);
      this.sessionExpired = lastLogin + this.SESSION_LIMIT * 1000 < new Date().getTime();
    }
    if (this.sessionExpired === true) {
      // clean expired session data
      this.storage.clear();
      this.redirectToLogin();
    }
    return this.sessionExpired;
  }

  redirectToLogin() {
    this.router.navigate([this.loginUrl]);
  }

  redirectToSmartyId() {
    // Redirect to CRM api to delete token and from there to Smarty ID
    window.location.href = this.smartyCrmApiLogoutUrl;
  }

  redirectToSmartyCrmApiLogin() {
    window.location.href = this.smartyCrmApiLoginUrl;
  }

  login(params) {
    if (params[this.TOKEN_REQUEST_KEY] != null && params[this.USER_REQUEST_KEY] != null) {
      this.storage.setItem(this.TOKEN_STORAGE_KEY, params[this.TOKEN_REQUEST_KEY]);
      this.storage.setItem(this.LAST_LOGIN_STORAGE_KEY, new Date().getTime().toString(16));
      this.requestUserDetails(params[this.USER_REQUEST_KEY]).subscribe(
        (user) => {
          if (user && user.id) {
            this.storage.setItem(this.USER_STORAGE_KEY, JSON.stringify(user));
            this.redirectBasedOnRole();
          }
        },
        (err) => {
          this.redirectToSmartyId();
        },
      );
    } else {
      this.redirectBasedOnRole();
    }
  }

  getToken(): string | null {
    return this.storage.getItem(this.TOKEN_STORAGE_KEY);
  }

  logout(): void {
    this.storage.clear();
    this.redirectToSmartyId();
  }

  requestUserDetails(user): Observable<User> {
    const url = environment.apiUrl + '/users/profile/' + user;
    const result$ = new BehaviorSubject<User>(new User());
    this.http.get<User>(url).subscribe((data: any) => {
      result$.next(new User(data));
    });
    return result$;
  }

  getLoggedUser() {
    const userData = this.storage.getItem(this.USER_STORAGE_KEY);
    return userData ? new User(JSON.parse(userData)) : null;
  }

  redirectBasedOnRole() {
    const loggedUser = this.getLoggedUser();
    if (loggedUser) {
      if (loggedUser.isTutor) {
        this.router.navigate(['/tutor-account/dashboard']);
      } else if (loggedUser.isCustomer) {
        this.router.navigate(['/customer-account/dashboard']);
      } else if (loggedUser.hasAdminAccess) {
        this.router.navigate(['/dashboard']);
      }
    } else {
      this.redirectToSmartyCrmApiLogin();
    }
  }
}
