import { Injectable } from '@angular/core';
import { FilterParserService } from '../../shared/filter-parser.service';

@Injectable({
  providedIn: 'root',
})
export class DataTablesParamsService extends FilterParserService {
  params: any;
  constructor() {
    super();
  }

  getDTParams(dtParameters, searchField, extraFilters = {}, forUi = false) {
    this.params = {};
    this.params.itemsPerPage = dtParameters.length;
    if (searchField && dtParameters.search.value) {
      this.params[searchField] = dtParameters.search.value;
    }
    this.params.page = dtParameters.start / dtParameters.length + 1;

    if (dtParameters.order) {
      for (const i in dtParameters.order) {
        if (dtParameters.order.hasOwnProperty(i)) {
          const columnIndex = dtParameters.order[i].column;
          const columnDir = dtParameters.order[i].dir;
          const column = dtParameters.columns[columnIndex];
          this.params['order[' + column.data + ']'] = columnDir;
        }
      }
    }
    // Parse the extra filters that are not mapped on DT
    if (extraFilters) {
      const apiParams = {};
      if (forUi) {
        for (const f in extraFilters) {
          if (extraFilters.hasOwnProperty(f) && typeof extraFilters[f].value !== 'undefined') {
            apiParams[f] = extraFilters[f].value;
          }
        }
      } else {
        for (const f in extraFilters) {
          if (extraFilters.hasOwnProperty(f) && typeof extraFilters[f].value !== 'undefined') {
            apiParams[extraFilters[f].apiFilter] = extraFilters[f].value;
          }
        }
      }
      this.params = { ...this.params, ...apiParams };

      // Remove nulls
      Object.keys(this.params).map((itemKey) => {
        if (this.params[itemKey] === null) {
          delete this.params[itemKey];
        }
      });
    }
    return this.params;
  }

  getDTPQueryString(dtParameters, searchField) {
    const DTParams = this.getDTParams(dtParameters, searchField);
    return Object.keys(DTParams)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(DTParams[key]);
      })
      .join('&');
  }

  /**
   * Overwrite DataTable Options based on queryParams
   * - order
   * - main search fierd
   * - items per page
   */
  setDTOptions(queryParams, dtOptions, search) {
    let newDtParams = {
      pageLength: dtOptions.pageLength,
    };
    const queryParamsKeys = Object.keys(queryParams);
    for (const k in queryParamsKeys) {
      if (queryParamsKeys.hasOwnProperty(k)) {
        const queryParamsValue = queryParams[queryParamsKeys[k]];

        // find the search field
        if (queryParamsKeys[k] === search) {
          newDtParams = { ...newDtParams, ...{ search: { search: queryParamsValue } } };
        }

        // Parse order param
        if (queryParamsKeys[k].indexOf('order[') > -1) {
          const orderKey = queryParamsKeys[k].replace('order[', '').replace(']', '');

          dtOptions.columns.map((c, idx) => {
            if (c.data === orderKey) {
              newDtParams = { ...newDtParams, ...{ order: [[idx, queryParamsValue]] } };
            }
          });
        }
      }
    }
    if (typeof queryParams.itemsPerPage !== 'undefined') {
      newDtParams.pageLength = queryParams.itemsPerPage;
    }
    return { ...dtOptions, ...newDtParams };
  }

  /**
   * @param urlHref
   * - base url
   * @param extraFilters
   *  - {uiGetParameter: {apiParameter: value}}
   */
  uiQueryString(urlHref, extraFilters = {}) {
    let queryParams = {};
    const uiParams = this.getUIParams(extraFilters);

    const orgUrl = new URL(urlHref);
    orgUrl.searchParams.forEach((v, k) => {
      queryParams[k] = v;
    });
    queryParams = { ...queryParams, ...uiParams };

    Object.keys(queryParams).map((itemKey) => {
      if (queryParams[itemKey] === null || queryParams[itemKey] === '') {
        delete queryParams[itemKey];
      }
    });

    return Object.keys(queryParams)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key]);
      })
      .join('&');
  }

  combineParamsAPI(extraFilters, extraParams) {
    const apiFilters = this.getApiParams(extraFilters);
    Object.keys(apiFilters).map((k) => {
      extraParams[k] = apiFilters[k];
      if (extraParams[k] === null || extraParams[k] === '') {
        delete extraParams[k];
      }
    });
    return extraParams;
  }

  combineParamsUI(extraFilters, extraParams) {
    const apiFilters = this.getApiParams(extraFilters);
    Object.keys(apiFilters).map((k) => {
      extraParams[k] = apiFilters[k];
      if (extraParams[k] === null || extraParams[k] === '') {
        delete extraParams[k];
      }
    });
    return extraParams;
  }

  getApiParams(extraFilters) {
    const params = {};
    for (const k in extraFilters) {
      if (extraFilters.hasOwnProperty(k)) {
        params[extraFilters[k].apiFilter] = extraFilters[k].value;
      }
    }
    return params;
  }
  getUIParams(extraFilters) {
    const params = {};
    for (const k in extraFilters) {
      if (extraFilters.hasOwnProperty(k)) {
        params[k] = extraFilters[k].value;
      }
    }
    return params;
  }
}
