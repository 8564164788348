import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { AuthGuardService } from '../core/auth/auth.guard.service';
import { RoleGuardService } from '../core/auth/role-guard.service';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivateChild: [RoleGuardService],
        data: { roles: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_ADMIN'] },
      },
      {
        path: 'dashboard/:date',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivateChild: [RoleGuardService],
        data: { roles: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_ADMIN'] },
      },
      {
        path: 'customers',
        loadChildren: () => import('./customer/customer.module').then((m) => m.CustomerModule),
        canActivateChild: [RoleGuardService],
        data: { roles: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_ADMIN'] },
      },
      {
        path: 'tutors',
        loadChildren: () => import('./tutor/tutor.module').then((m) => m.TutorModule),
        canActivateChild: [RoleGuardService],
        data: { roles: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_ADMIN'] },
      },
      {
        path: 'tags',
        loadChildren: () => import('./tag/tag.module').then((m) => m.TagModule),
        canActivateChild: [RoleGuardService],
        data: { roles: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_ADMIN'] },
      },
      {
        path: 'schedule',
        loadChildren: () => import('./schedule/schedule.module').then((m) => m.ScheduleModule),
        canActivateChild: [RoleGuardService],
        data: { roles: ['ROLE_ADMIN', 'ROLE_TUTOR', 'ROLE_ORGANIZATION_ADMIN'] },
      },
      {
        path: 'schedule/list',
        loadChildren: () => import('./schedule/list/list.module').then((m) => m.ListModule),
        canActivateChild: [RoleGuardService],
        data: { roles: ['ROLE_TUTOR'] },
      },
      {
        path: 'invoices',
        loadChildren: () => import('./invoices/invoices.module').then((m) => m.InvoicesModule),
        canActivateChild: [RoleGuardService],
        data: { roles: ['ROLE_CUSTOMER', 'ROLE_ADMIN', 'ROLE_ORGANIZATION_ADMIN'] },
      },
      {
        path: 'payments',
        loadChildren: () => import('./payments/payments.module').then((m) => m.PaymentsModule),
        canActivateChild: [RoleGuardService],
        data: { roles: ['ROLE_ADMIN', 'ROLE_TUTOR', 'ROLE_ORGANIZATION_ADMIN'] },
      },
      {
        path: 'users',
        loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
        canActivateChild: [RoleGuardService],
        data: { roles: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_ADMIN'] },
      },
      {
        path: 'tutor-account',
        loadChildren: () => import('./tutor-account/tutor-account.module').then((m) => m.TutorAccountModule),
        canActivateChild: [RoleGuardService],
        data: { roles: ['ROLE_ADMIN', 'ROLE_TUTOR', 'ROLE_ORGANIZATION_ADMIN'] },
      },
      {
        path: 'customer-account',
        loadChildren: () => import('./customer-account/customer-account.module').then((m) => m.CustomerAccountModule),
        canActivateChild: [RoleGuardService],
        data: { roles: ['ROLE_CUSTOMER', 'ROLE_ORGANIZATION_ADMIN'] },
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
        canActivateChild: [RoleGuardService],
        data: { roles: ['ROLE_ADMIN', 'ROLE_ORGANIZATION_ADMIN'] },
      },
      {
        path: 'organizations',
        loadChildren: () => import('./organization/organization.module').then((m) => m.OrganizationModule),
        canActivateChild: [RoleGuardService],
        data: { roles: ['ROLE_ADMIN'] },
      },
    ],
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
