<div class="container-fluid">
  <app-main-menu></app-main-menu>
  <app-share-tools></app-share-tools>
  <div class="container-fluid content">
    <div class="row">
      <div class="col-md-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
