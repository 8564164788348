import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user.model';
import { DataTablesParamsService } from './datatables-params.service';
import { generateUrl } from '../helpers/query-params.helper';
import { JsonResponse } from '../models/json-response.model';
import * as moment from 'moment-timezone';
import { DatatablesSearchService } from './datatables-search.service';
import { debounceTime } from 'rxjs/operators';
import { Organization } from '../models/organization.model';
import { Engagement } from '../models/engagement.model';
import { Student } from '../models/student.model';

@Injectable({
  providedIn: 'root',
})
export class UserService extends DataTablesParamsService {
  private readonly endpoint: string;
  private readonly smartyUsersEndpoint: string;
  private readonly smartyIdPasswordEndpoint: string;
  private readonly userListEndpoint: string;

  constructor(private http: HttpClient, private datatablesSearch: DatatablesSearchService) {
    super();
    this.endpoint = environment.apiUrl + '/users';
    this.smartyUsersEndpoint = environment.apiUrl + '/smarty_users';
    this.smartyIdPasswordEndpoint = environment.smartyIdApiUrl + '/user/change-password';
    this.userListEndpoint = environment.apiUrl + '/user-list';
  }

  findAll(queryParams = {}): Observable<User[]> {
    const headers = new HttpHeaders().set('accept', 'application/ld+json');
    const result$ = new BehaviorSubject<User[]>([]);
    this.http.get<User[]>(generateUrl(queryParams, this.endpoint)).subscribe((data: any) => {
      const result = [];
      for (const item of data) {
        result.push(new User(item));
      }
      result$.next(result);
    });
    return result$;
  }

  find(id: string): Observable<User> {
    const result$ = new BehaviorSubject(new User());
    this.http.get<User>(this.endpoint + '/' + id).subscribe((data: any) => {
      for (const i in data.appAccess) {
        if (!Array.isArray(data.appAccess[i].roles)) {
          data.appAccess[i].roles = new Array(data.appAccess[i].roles);
        }
      }
      result$.next(new User(data));
    });
    return result$;
  }

  save(resource: User): Observable<User> {
    const result$ = new BehaviorSubject(resource);

    if (resource.id) {
      this.http.put<any>(this.endpoint + '/' + resource.id, resource).subscribe((data: any) => {
        const newResource = new User(data);
        result$.next(newResource);
      });
    } else {
      this.http.post<any>(this.endpoint, resource).subscribe((data: any) => {
        const newResource = new User(data);
        result$.next(newResource);
      });
    }
    return result$;
  }

  // Smarty User DTO find
  findSmartyUser(id: string): Observable<User> {
    const result$ = new BehaviorSubject(new User());
    this.http.get<User>(this.smartyUsersEndpoint + '/' + id).subscribe((data: any) => {
      for (const i in data.appAccess) {
        if (!Array.isArray(data.appAccess[i].roles)) {
          data.appAccess[i].roles = new Array(data.appAccess[i].roles);
        }
      }
      result$.next(new User(data));
    });
    return result$;
  }

  // Smarty User DTO Save
  saveSmartyUser(resource: User): Observable<User> {
    const result$ = new BehaviorSubject(resource);
    if (resource.organization) {
      resource.organization = new Organization({ id: resource.organization }).IRI;
    }

    if (resource.id) {
      this.http.put<any>(this.smartyUsersEndpoint + '/' + resource.id, resource).subscribe(
        (data: any) => {
          const newResource = new User(data);
          result$.next(newResource);
        },
        (err) => {
          result$.error(err);
        },
      );
    } else {
      this.http.post<any>(this.smartyUsersEndpoint, resource).subscribe(
        (data: any) => {
          const newResource = new User(data);
          result$.next(newResource);
        },
        (err) => {
          result$.error(err);
        },
      );
    }
    return result$;
  }

  changePassword(resource): Observable<JsonResponse> {
    const result$ = new BehaviorSubject<JsonResponse>(new JsonResponse());
    this.http.post<JsonResponse>(this.smartyIdPasswordEndpoint, resource).subscribe(
      (data: any) => {
        result$.next(data);
      },
      (err) => {
        result$.error(err);
      },
    );
    return result$;
  }

  delete(resource: User): Observable<any> {
    return this.http.delete(this.endpoint + '/' + resource.id);
  }

  getDTUrl(dataTablesParameters, searchField, extraParams = {}) {
    let params = this.getDTParams(dataTablesParameters, searchField);
    if (Object.keys(extraParams).length > 0) {
      params = Object.assign({}, extraParams, params);
      for (const i in params) {
        if (params.hasOwnProperty(i) && !params[i]) {
          delete params[i];
        }
      }
    }
    return generateUrl(params, this.userListEndpoint + '/all');
  }

  getTimezones() {
    const timezones = [];
    moment.tz.names().map((region) => {
      timezones.push({ id: region, label: region.replace('_', ' ') });
    });
    return timezones;
  }

  dtSearchDebouncer(url: string, callback): Observable<User[]> {
    const results = [];
    const result$ = new BehaviorSubject<User[]>([]);
    const dtSearchResult = this.datatablesSearch.searchDebouncer(url, callback);
    if (dtSearchResult) {
      dtSearchResult.subscribe((data: any) => {
        data.map((user: User) => {
          results.push(new User(user));
        });
        result$.next(results);
      });
    }
    return result$;
  }
  customReport(queryParams = {}): Observable<any> {
    const result$ = new BehaviorSubject<any>(null);
    this.http.get<any>(generateUrl(queryParams, this.endpoint + '/report/custom')).subscribe((data: any) => {
      result$.next(data);
    });
    return result$;
  }

  findEngagement(id: number): Observable<Engagement[]> {
    const url = generateUrl({}, this.endpoint + '/' + id + '/engagement');
    const result$ = new BehaviorSubject<Engagement[]>([]);
    this.http.get<Engagement[]>(url.toString()).subscribe((data: any) => {
      const result = [];
      for (const item of data) {
        result.push(new Engagement(item));
      }
      result$.next(result);
    });
    return result$;
  }
}
