import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareToolsComponent } from './share-tools.component';
import { PopoverModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [ShareToolsComponent],
  exports: [ShareToolsComponent],
  imports: [CommonModule, PopoverModule.forRoot()],
})
export class ShareToolsModule {}
