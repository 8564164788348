import { Injectable } from '@angular/core';
import { generateUrl, getUrl } from '../core/helpers/query-params.helper';

@Injectable({
  providedIn: 'root',
})
export class FilterParserService {
  private filterParams: FilterParam[] = [];

  constructor() {}

  addDTParams(dtParameters: any, dtSearchName = ''): void {
    this.addNewOrUpdate('itemsPerPage', dtParameters.length);

    const pageNo = dtParameters.start / dtParameters.length + 1;
    this.addNewOrUpdate('page', pageNo);

    if (dtSearchName) {
      this.addNewOrUpdate(dtSearchName, dtParameters.search.value);
    }

    if (dtParameters.order) {
      // remove applied order parameter
      this.getParams().map((p) => {
        if (p.getApiKey().indexOf('order[') === 0) {
          this.removeParam(p.getApiKey());
        }
      });

      for (const i in dtParameters.order) {
        if (dtParameters.order.hasOwnProperty(i)) {
          const columnIndex = dtParameters.order[i].column;
          const columnDir = dtParameters.order[i].dir;
          const column = dtParameters.columns[columnIndex];

          this.addNewOrUpdate('order[' + column.data + ']', columnDir);
        }
      }
    }
  }

  getApiUrl(endpoint: string): string {
    const params = {};
    for (const i in this.filterParams) {
      if (this.filterParams.hasOwnProperty(i)) {
        const filterParam = this.filterParams[i];
        if (filterParam.getValue() !== '' && filterParam.getValue() !== null) {
          params[filterParam.getApiKey()] = filterParam.getValue();
        }
      }
    }
    return generateUrl(params, endpoint);
  }

  getUrlState(): string {
    const fullPath = window.location.origin + window.location.pathname;

    const params = {};
    for (const i in this.filterParams) {
      if (this.filterParams.hasOwnProperty(i)) {
        const filterParam = this.filterParams[i];
        if (filterParam.getValue() !== '' && filterParam.getValue() !== null && !filterParam.isExcluded()) {
          const paramName = filterParam.getUiKey() ? filterParam.getUiKey() : filterParam.getApiKey();
          params[paramName] = filterParam.getValue();
        }
      }
    }
    const url = getUrl(params, fullPath);

    return url.pathname + url.search;
  }

  addParam(filterParam: FilterParam): void {
    this.filterParams.push(filterParam);
  }
  setParams(params: FilterParam[]): void {
    this.filterParams = params;
  }
  getParam(name: string): FilterParam | null {
    const params = this.filterParams.filter((p) => p.getApiKey() === name);
    return params.length === 1 ? params[0] : null;
  }
  getParams(): FilterParam[] {
    return this.filterParams;
  }

  addNewOrUpdate(name: string, value: any): FilterParam {
    const paramFilter = this.getParam(name);
    if (paramFilter === null) {
      this.addParam(new FilterParam(name).setValue(value));
    } else {
      paramFilter.setValue(value);
    }
    return paramFilter;
  }
  /**
   * @deprecated use addNewOrUpdate
   */
  private getNewOrExistingParam(name: string): FilterParam {
    let param = this.getParam(name);
    if (param === null) {
      param = new FilterParam(name);
    }
    return param;
  }

  removeParam(name: string): void {
    this.filterParams.map((p) => {
      if (p.getApiKey() === name) {
        const index = this.filterParams.indexOf(p);
        this.filterParams.splice(index, 1);
      }
    });
  }

  updateParam(name: string, data: any): void {
    this.filterParams.map((p) => {
      if (p.getApiKey() === name) {
        p.setValue(data);
      }
    });
  }
  parseUiParams(params, filtersSchema: object): void {
    Object.keys(params).forEach((pName) => {
      if (filtersSchema.hasOwnProperty(pName)) {
        const filter = this.addNewOrUpdate(filtersSchema[pName], params[pName]);
        filter.setUiKey(pName);
      }
    });
  }
}

export class FilterParam {
  private readonly apiKey: string;
  private uiExcluded = false; // Excluded from Browser URL
  private uiKey = ''; // If is missing the apiKey will be used
  private value = null;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
  }
  getApiKey() {
    return this.apiKey;
  }
  setUiKey(name: string) {
    this.uiKey = name;
    return this;
  }
  getUiKey() {
    return this.uiKey;
  }
  setValue(data: any) {
    this.value = data;
    return this;
  }
  getValue() {
    return this.value;
  }
  setIsExcluded(excluded: boolean) {
    this.uiExcluded = excluded;
    return this;
  }
  isExcluded() {
    return this.uiExcluded;
  }
}
