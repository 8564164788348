<nav class="navbar navbar-expand-lg navbar-light justify-content-between">
  <a class="navbar-brand" routerLink="/">
    <img src="assets/img/brandmark.svg" alt="Smarty CRM" />
  </a>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarNavDropdown"
    aria-controls="navbarNavDropdown"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown" *ngIf="loggedUser">
    <ul class="navbar-nav ml-auto " *ngIf="loggedUser.hasAdminAccess || loggedUser.isTutor">
      <li class="nav-item">
        <ul class="navbar-nav ml-auto sub-menu" *ngIf="loggedUser.hasAdminAccess">
          <li class="nav-item">
            <span class="nav-link text-black-50">Welcome, {{ loggedUser.email }}</span>
          </li>
          <li class="nav-item">
            <app-async-user-quick-search></app-async-user-quick-search>
          </li>
          <li class="nav-item">
            <a class="nav-link {{ activeDashboard ? 'current' : '' }}" routerLink="/">Dashboard</a>
          </li>
          <li class="nav-item">
            <a class="nav-link {{ activeManage ? 'current' : '' }}" routerLink="/customers">Manage</a>
            <ul class="navbar-nav ml-auto sub-menu" *ngIf="loggedUser.hasAdminAccess && activeManage">
              <li class="nav-item" *ngIf="loggedUser.isAdmin">
                <a class="nav-link" routerLink="/organizations" [routerLinkActive]="['current']">Organizations</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/customers" [routerLinkActive]="['current']">Customers</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/tutors" [routerLinkActive]="['current']">Tutors</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/users" [routerLinkActive]="['current']">Users</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/users/import" [routerLinkActive]="['current']">Import</a>
              </li>
            </ul>
          </li>
          <li class="nav-item" *ngIf="loggedUser.isOrganizationAdmin">
            <a class="nav-link" routerLink="/schedule" [routerLinkActive]="['current']">Schedule</a>
          </li>
          <li class="nav-item" *ngIf="loggedUser.isOrganizationAdmin">
            <a class="nav-link {{ activeBilling ? 'current' : '' }}" routerLink="/invoices/sent">Billing</a>
            <ul class="navbar-nav ml-auto sub-menu" *ngIf="loggedUser.hasAdminAccess && activeBilling">
              <li class="nav-item">
                <a class="nav-link" routerLink="/invoices/sent" [routerLinkActive]="['current']">Sent</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/payments" [routerLinkActive]="['current']">Payments</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/invoices/create" [routerLinkActive]="['current']">Create invoices</a>
              </li>
            </ul>
          </li>
          <li class="nav-item" *ngIf="loggedUser.isOrganizationAdmin">
            <a
              class="nav-link {{ activeReports ? 'current' : '' }}"
              routerLink="/reports/overall"
              [routerLinkActive]="['current']"
            >Reports</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/logout">Logout</a>
          </li>
        </ul>
      </li>
      <li class="nav-item">
        <ul class="navbar-nav ml-auto sub-menu" *ngIf="loggedUser.isTutor">
          <li class="nav-item">
            <span class="nav-link text-black-50">Welcome, {{ loggedUser.email }}</span>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/tutor-account/dashboard" [routerLinkActive]="['current']">Dashboard</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/schedule" [routerLinkActive]="['current']">Schedule</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/tutor-account/assignments" [routerLinkActive]="['current']">Assignments</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/tutor-account/profile" [routerLinkActive]="['current']">Account/Profile</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/tutor-account/session-report" [routerLinkActive]="['current']">Report</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/logout">Logout</a>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto" *ngIf="loggedUser.isCustomer">
      <li class="nav-item">
        <span class="nav-link text-black-50">Welcome, {{ loggedUser.email }}</span>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/customer-account/dashboard" [routerLinkActive]="['current']">Dashboard</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/customer-account/profile" [routerLinkActive]="['current']">Edit profile</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/customer-account/sessions" [routerLinkActive]="['current']">Sessions</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/invoices/sent">Invoices</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/logout">Logout</a>
      </li>
    </ul>
  </div>
</nav>
