export const environment = {
  production: false,
  prefApiUrl: '/api',
  apiUrl: 'https://crm-api-staging2.smartyprep.me/api',
  smartyIdUrl: 'https://oauth-staging.smartyprep.me',
  loginUrl: 'https://crm-api-staging2.smartyprep.me/login',
  smartyBubbleApiUrl: 'https://staging-app.smartybubble.com/api/tests/results/19efe0ba3fd96d1e73b00f7289246we2',
  smartyBubbleUrl: 'https://staging-app.smartybubble.com',
  crmServerBaseUrl: 'https://crm-api-staging2.smartyprep.me',
  stripeKey: 'pk_test_EGE5gpSq3VgMHrkM2Fq2b7ST',
  get smartyIdApiUrl() {
    return this.smartyIdUrl + '/api';
  },
  get smartyIdAccountUrl() {
    return this.smartyIdUrl + '/account';
  },
};
