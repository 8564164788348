<div class="row">
  <div class="col-md-4"></div>
  <div class="col-4 align-middle text-center">
<!--    <div class="text-info">-->
<!--      {{-->
<!--        organizationData.referralLinkText ? organizationData.referralLinkText : 'Refer a friend.  Give them $ credit.'-->
<!--      }}-->
<!--      <i-->
<!--        *ngIf="organizationData"-->
<!--        [popover]="organizationData.referralDetails"-->
<!--        placement="top"-->
<!--        triggers="mouseenter:mouseleave"-->
<!--        class="fa fa-question-circle"-->
<!--      ></i>-->
<!--      <span class="btn btn-sm text-info font-weight-bold" (click)="copyMessage()">Click to copy link.</span>-->
<!--    </div>-->
  </div>
  <div class="col-md-4" *ngIf="loggedUser.isTutor && loggedUser.id">
    <div *ngIf="!loggedUser.hasPaymentDetails" class="alert float-right p-1 alert-warning">
      No Payment details for this account.
    </div>
  </div>
</div>
